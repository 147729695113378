import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';

export default function Modal({ title, content, onCloseModal = () => {} }) {
  const closeModal = () => {
    onCloseModal();
  };
  return (
    <div className="modal">
      <div className="modal__window">
        <div className="modal__header">
          <h2>{title ?? ' '}</h2>
          <button className="modal__close-button" onClick={closeModal} aria-label="Close modal">
            <FontAwesomeIcon icon={faXmark} className="fa-2x" />
          </button>
        </div>

        <div className="modal__content">
          {content ? content : <p>Unknown error. Please try again.</p>}
        </div>
      </div>
    </div>
  );
}
