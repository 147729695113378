import React from 'react';
import './styles.scss';
import usePageTracking from '../../usePageTracking';

export default function Faq() {
  
  // Tracking
  usePageTracking();

  return (
    <div className="app__wrapper faq">
      <h1>FAQ</h1>

      <div className="faq__section">
        <h2>General Information</h2>
        <div className="faq__section-contents">
          <p><strong>Q: What does this site do?</strong></p>
          <p>A: This site provides a service to convert Swagger JSON files into Markdown or PDF documents. It helps make API documentation easier to read and share.</p>
        </div>
        <div className="faq__section-contents">
          <p><strong>Q: What is Swagger JSON?</strong></p>
          <p>A: Swagger JSON is a file that describes the structure and behavior of an API, defining its endpoints, methods, parameters, responses, and more.</p>
        </div>
        <div className="faq__section-contents">
          <p><strong>Q: What Swagger versions do you support?</strong></p>
          <p>A: We only support Swagger 3.0. For more information on Swagger 3.0, visit the <a href="https://swagger.io/specification/v3/" target="_blank" rel="noopener noreferrer">Swagger 3.0 documentation</a>.</p>
        </div>
      </div>

      <div className="faq__section">
        <h2>Usage</h2>
        <div className="faq__section-contents">
          <p><strong>Q: How do I upload a Swagger JSON file?</strong></p>
          <p>A: You can upload a Swagger JSON file by clicking the "Choose File" button or by entering text.</p>
        </div>
        <div className="faq__section-contents">
          <p><strong>Q: How does the conversion process work?</strong></p>
          <p>A: After uploading your file, choose the conversion format (Markdown or PDF) and click the "Submit" button. Once the conversion is complete, a download page will be provided.</p>
        </div>
        <div className="faq__section-contents">
          <p><strong>Q: Can I download the converted file multiple times?</strong></p>
          <p>A: The converted file can only be downloaded once. If you need to download it again, you will need to re-upload the Swagger JSON file and convert it again.</p>
        </div>
      </div>

      <div className="faq__section">
        <h2>Support</h2>
        <div className="faq__section-contents">
          <p><strong>Q: How can I get support or provide feedback?</strong></p>
          <p>A: If you need further assistance or would like to provide feedback, you can select and use the Feedback button. Click <a href="https://forms.gle/L1wfvGXf5Ns2Hmj37" target="_blank" rel="noopener noreferrer">Feedback</a> to access a form where you can detail your concerns or suggestions.</p>
        </div>
      </div>

      <div className="faq__section">
        <h2>Miscellaneous</h2>
        <div className="faq__section-contents">
          <p><strong>Q: Where are the converted files stored?</strong></p>
          <p>A: The converted file is saved to the specified download path on your local device.</p>
        </div>
        <div className="faq__section-contents">
          <p><strong>Q: Are the uploaded files stored on your server?</strong></p>
          <p>A: No, we do not store the uploaded files. We only process the files for conversion and provide you with the new converted file.</p>
        </div>
      </div>
    </div>
  );
}
