import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Link, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import CommentButton from '../../components/commentButton';
import Loader from '../../components/loader/index.js';
import Modal from '../../components/modal/index.js';
import Navigation from '../../components/navigation';
import { Select } from '../../components/select/index.js';
import usePageTracking from '../../usePageTracking';
import Download from '../download/index.js';
import Faq from '../faq';
import HowToUse from '../howtouse/index.js';
import './styles.scss';

function Home() {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [textAreaText, setTextAreaText] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const ref = useRef(null);
  const navigate = useNavigate();

  // Tracking
  usePageTracking();

  useEffect(() => {
    // Keep submit button disabled if mandatory inputs are not filled
    if (!from || !to) {
      setIsDisabled(true);
    } else if ((from.value === 'raw' && !textAreaText) || (from.value === 'file' && !file)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [from, to, textAreaText, file]);

  const dropHandler = (e) => {
    e.preventDefault(); // Stop file from being opened in browser
    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          setFile(file);
          setIsFileSelected(true);
        }
      });
    } else {
      [...e.dataTransfer.files].forEach((file, i) => {
        setFile(file);
        setIsFileSelected(true);
      });
    }
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsFileSelected(true);
  };

  const onSubmit = async () => {
    const spinnerTimeout = new Promise((resolve) => setTimeout(resolve, 1500));
    setShowSpinner(true);
    if (!from || !to) return;

    let jsonString;
    if (from.value === 'file' && file) {
      jsonString = await file.text();
    } else if (from.value === 'raw') {
      const textArea = document.querySelector('.app__text-area');
      jsonString = textArea.value;
    }

    if (window.gtag) {
      window.gtag('event', 'submit_button_click', {
        event_label: 'submit',
        submit_swagger: jsonString,
        submit_format: to.label
      });
    }

    try {
      const apiHost = process.env.REACT_APP_S2P_API;
      const apiPath = '/api/print';
      const apiUrl = `${apiHost}${apiPath}`;

      const response = await axios.post(apiUrl, jsonString, {
        headers: {
          'Content-Type': 'application/json',
          'X-S2P-FORMAT': to.value
        },
        responseType: 'arraybuffer'
      });

      const filename = response.headers['x-s2p-filename'];
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = URL.createObjectURL(blob);

      await Promise.all([spinnerTimeout, response]);
      setShowSpinner(false);
      navigate('/download', { state: { downloadUrl: url, filename } });
    } catch (error) {
      if (error.response && error.response.data) {
        try {
          const errorData = JSON.parse(
            new TextDecoder('utf-8').decode(new Uint8Array(error.response.data))
          );
          setShowErrorModal(true);
          setErrorMessage(errorData.message);
        } catch (e) {
          setShowErrorModal(true);
          setErrorMessage('Error occurred.  Please try again.');
        }
      } else {
        setShowErrorModal(true);
        setErrorMessage('Error occurred.  Please try again.');
      }
      setShowSpinner(false);
    }
  };

  return (
    <>
      <div className="app">
        <div className="app__wrapper app__title-wrapper">
          <Link to="/" className="app__title-link">
            <h1 className="app__title">SWAGGER-2-PRINT</h1>
          </Link>

          <h2 className="app__subtitle">
            Swagger is an open-source framework for designing, building, documenting and testing
            RESTful APIs.
            <br />
            <br />
            With Swagger2Print, you can convert Swagger to PDF or Markdown API documentation.
          </h2>
        </div>
        <div className="app__content-wrapper">
          <div className="app__select-wrapper">
            <Select
              label="Swagger 3.0"
              onSelect={(val) => setFrom(val)}
              options={[
                { label: 'Raw', value: 'raw' },
                { label: 'File', value: 'file' }
              ]}
            />
          </div>
          <div className="app__icon-wrapper">
            <FontAwesomeIcon icon={faArrowRight} className="fa-2x" />
          </div>
          <div className="app__select-wrapper">
            <Select
              label="Format"
              onSelect={(val) => setTo(val)}
              options={[
                { label: 'Markdown', value: 'Markdown' },
                { label: 'PDF', value: 'Pdf' }
              ]}
            />
          </div>
          <button className="app__submit-button" onClick={onSubmit} disabled={isDisabled}>
            Submit
          </button>
        </div>
        {from?.value === 'raw' && (
          <div className="app__content-wrapper">
            <textarea
              className="app__text-area"
              onChange={(e) => setTextAreaText(e.target.value)}
            />
          </div>
        )}
        {from?.value === 'file' && (
          <>
            <div
              ref={ref}
              className={`app__file-input-wrapper ${isFileSelected ? 'file-selected' : ''}`}
              onDrop={(e) => dropHandler(e)}
              onDragOver={(e) => dragOverHandler(e)}>
              <label
                className={`app__file-label ${isFileSelected ? 'file-selected' : ''}`}
                htmlFor="fileInput"
                tabIndex="0">
                Choose File
              </label>
              <input
                id="fileInput"
                name="fileInput"
                type="file"
                accept=".json"
                onChange={onFileChange}
              />
              <div className="upload__file-name">{file && <p>{file.name}</p>}</div>
            </div>
          </>
        )}
        <div>
          <HowToUse />
        </div>
      </div>
      {showErrorModal ? (
        <Modal title="Error" content={errorMessage} onCloseModal={() => setShowErrorModal(false)} />
      ) : null}
      {showSpinner ? <Loader /> : null}
      <CommentButton />
    </>
  );
}

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/download" element={<Download />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </Router>
  );
}

export default App;
