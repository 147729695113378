import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo192.png';
import './styles.scss';

export default function Navigation() {
  const [openMenu, setOpenMenu] = useState(false);
  const mobileNavRef = useRef(null);

  const handleClick = (event) => {
    // Close menu if the user presses outside the mobile nav
    if (!mobileNavRef.current.contains(event.target)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [mobileNavRef]);

  return (
    <nav className="nav">
      <div className="nav__mobile" ref={mobileNavRef}>
        <button
          className="nav__mobile-hamburger"
          onClick={() => setOpenMenu(true)}
          aria-label="Open mobile navigation menu">
          <FontAwesomeIcon icon={faBars} className="fa-2x" color="white" />
        </button>
        <div className={`nav__mobile-drawer ${openMenu ? 'open' : ''}`}>
          <ul>
            <li>
              <button
                className="nav__mobile-menu-close"
                onClick={() => setOpenMenu(false)}
                aria-label="Close mobile navigation menu">
                <FontAwesomeIcon icon={faX} className="fa-2x" color="white" />
              </button>
            </li>
            <li>
              <Link to="/" onClick={() => setOpenMenu(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/faq" onClick={() => setOpenMenu(false)}>
                FAQ
              </Link>
            </li>
            <li className="navigation__feedback">
              <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSdERYzOpkAdcSgudoRH0sYYYBx6tOpBzSlWSPAX5BTm5BDXoQ/viewform"
                target="_blank"
                onClick={() => setOpenMenu(false)}>
                Feedback
              </Link>
            </li>
            <li className="kofi_button_container" id="kofi-button-container"></li>
          </ul>
        </div>
      </div>
      <div className="nav__desktop">
        <Link to="/">
          <img src={logo} className="nav__logo" alt="logo png" />
        </Link>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li className="navigation__feedback">
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSdERYzOpkAdcSgudoRH0sYYYBx6tOpBzSlWSPAX5BTm5BDXoQ/viewform"
              target="_blank">
              Feedback
            </Link>
          </li>
          <li className="kofi_button_container" id="kofi-button-container2"></li>
        </ul>
      </div>
    </nav>
  );
}
