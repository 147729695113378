import React from 'react';
import './styles.scss';

function HowToUse() {
  return (
    <div className="how-to-use">
      <h2>How to Use</h2>
      <ol>
        <li>
          <h3>Swagger 3.0</h3>
          <p>Choose one of the following options to input your Swagger JSON:</p>
          <ul>
            <li><strong>Raw:</strong> Input the Swagger JSON text.</li>
            <li><strong>File:</strong> Upload the Swagger JSON file.</li>
          </ul>
        </li>
        <li>
          <h3>Select Format</h3>
          <p>In Swagger2Print, you can choose from the following document format options:</p>
          <ul>
            <li><strong>Markdown:</strong> Convert swagger to markdown file.</li>
            <li><strong>PDF:</strong> Convert swagger to pdf file.</li>
          </ul>
        </li>
        <li>
          <h3>Submit</h3>
          <p>After completing the settings, click the "Submit" button. Swagger2Print will generate the api document in the specified format based on the uploaded Swagger file.</p>
        </li>
        <li>
          <h3>Download Document</h3>
          <p>Once api document is generated, you will be redirected to a page where you can download the document. Click the download button to receive your document.<br/>Note that the document can only be downloaded once.</p>
          <p></p>
        </li>
      </ol>
    </div>
  );
}

export default HowToUse;