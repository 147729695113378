import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../components/modal/index.js';
import dino from '../../images/dino.png';
import usePageTracking from '../../usePageTracking';
import CommentButton from '../../components/commentButton';
import './styles.scss';

export default function Download() {
  const location = useLocation();
  const [isDownloaded, setIsDownloaded] = useState(false);
  const { downloadUrl, filename } = location.state || {};
  const [downloadCount, setDownloadCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const navigate = useNavigate();

  // Tracking
  usePageTracking();

  useEffect(() => {
    if (!downloadUrl) {
      navigate('/');
    }
  }, []);

  const onDownload = (e) => {
    if (!downloadUrl) {
      e.preventDefault();
      setShowModal(true);
      setModalMessage('No file available for download.');
      return;
    }

    setDownloadCount((prevCount) => {
      const newCount = prevCount + 1;

      if (window.gtag) {
        window.gtag('event', 'download_button_click', {
          event_label: 'download',
          download_name: filename,
          download_count: newCount
        });
      }

      return newCount;
    });

    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setIsDownloaded(true);
    }, 500);
  };

  return (
    <div className="app">
      <Link to="/" className="app__title-link">
        <h1 className="app__title">SWAGGER-2-PRINT</h1>
      </Link>
      <h2 className="app__subtitle">Here you can download the file.</h2>
      <div className="app__content-wrapper-vertical">
        <div className="app__download-button-wrapper">
          {isDownloaded ? (
            <div className="app__pre-download-wrapper">
              <img src={dino} alt="dino png" width="300px" height="300px" />
              <p>Your file is now in your hands. Enjoy!</p>
            </div>
          ) : (
            <div className="app__pre-download-wrapper">
              <p className="app__description">Click!</p>
              <button className="app__download-button" onClick={(e) => onDownload(e)}>
                Download
              </button>
            </div>
          )}
        </div>
        <div className='app__contents'>
          <p><b>Help us shape the future of our service with your valuable <span style={{ color: '#870404' }}>feedback</span>.</b></p>
          <p><b>Share your thoughts and make a difference!</b></p>
        </div>
      </div>
      {showModal ? <Modal content={modalMessage} onCloseModal={() => setShowModal(false)} /> : null}
      <CommentButton />
    </div>
  );
}
