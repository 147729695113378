import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import './styles.scss';

export const Select = (props) => {
  const { label, selectedValue, options, onSelect } = props;
  const [selected, setSelected] = useState(selectedValue ?? { label: '', value: '' });
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  const onClick = (value) => {
    if (onSelect) {
      onSelect(value);
    }
    setSelected(value);
    setShowOptions(false);
  };

  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);

  return (
    <div className="select" ref={ref}>
      <label className="select__label">{label}</label>
      <div className="select__group">
        <button className="select__box" onClick={() => setShowOptions(!showOptions)}>
          <div>{selected.label === '' ? 'Select' : selected.label}</div>
          <FontAwesomeIcon icon={faCaretDown} />
        </button>
        {showOptions ? (
          <div className="select__options-wrapper">
            {options?.map((option) => {
              return (
                <button
                  className="select__options"
                  key={option.label}
                  name={option.value}
                  onClick={() => onClick(option)}>
                  {option.label}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
