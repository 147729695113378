import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.scss';

export default function Loader() {
  return (
    <div className="loader">
      <div className="loader__spinner-wrapper">
        <FontAwesomeIcon icon={faSpinner} className="fa-4x spinner" />
      </div>
    </div>
  );
}
