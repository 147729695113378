import './styles.scss';

export default function CommentButton() {

  const onFeedback = (e) => {    
    if (window.gtag) {
      window.gtag('event', 'feedback_button_click', {
        event_label: 'feedback'
      });
    }
  };

  return (
    <div className="commentButton">
      <a
        href="https://forms.gle/L1wfvGXf5Ns2Hmj37"
        target="_blank"
        className="commentButton__button"
        rel="noreferrer"
        onClick={onFeedback}>
        Feedback
      </a>
    </div>
  );
}
